<template>
  <div>
    <div
      style="
        border-bottom: 1px solid #e6e6e6;
        padding: 10px 15px;
        position: relative;
      "
    >
      <div style="display: inline-block; width: 60px">
        <img style="width: 100%" src="../../assets/guanghe.png" alt="" />
      </div>
      <div style="display: inline-block; position: absolute; top: 17px">
        <div style="font-size: 18px">光合城晔光小站</div>
        <div>志愿者接单服务</div>
      </div>
      <div style="position: absolute; top: 25px; right: 25px">
        <router-link to="/guanghe/evaluating">
          <van-button type="info" size="small">已接订单</van-button>
        </router-link>
      </div>
    </div>
    <div class="bianmin">
      <div v-for="item in list" :key="item" class="bm-lists">
        <div class="bm-listsA">
          心愿订单 - {{ item.Name }} - {{ item.StatusName }}
        </div>
        <div class="bm-listsB">
          <div>发 起 人 ： {{ item.Name }}</div>
          <div>联系电话： {{ item.Mobile }}</div>
          <div>订单地址： {{ item.Address }}</div>
          <div>订单时间： {{ item.AddTime }}</div>
          <div>订单需求： {{ item.Help }}</div>
        </div>
        <div class="button">
          <van-button type="primary" @click="jiedan(item.ID)">接单</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { Dialog } from "vant";
Vue.use(Dialog);
import { WxGetDesireHelpList, WxHandlerDesireHelp } from "@/api/yeguang";
import { getOpenId } from "@/utils/auth";
export default {
  data() {
    return {
      pagelimin: { page: 1, limit: 1000 },
      activeNames: ["1"],
      list: [],
    };
  },
  created() {
    this.DesireHelpList();
  },
  methods: {
    // 获取列表
    DesireHelpList: function () {
      WxGetDesireHelpList(this.pagelimin).then((res) => {
        this.list = res.data.data;
        for (var i = 0; i < this.list.length; i++) {
          if (this.list[i].Status == 1) {
            this.list[i].StatusName = "公示中";
          } else {
            this.list[i].StatusName = "已结束";
          }
        }
      });
    },
    // 接单
    jiedan: function (row) {
      Dialog.confirm({
        title: "接单提醒",
        message: "是否接取该订单!",
      })
        .then(() => {
          WxHandlerDesireHelp({ ID: row, OpenID: getOpenId() }).then((res) => {
            if (res.data.code == 0) {
              Dialog.alert({
                title: "提示",
                message: "接单成功!",
              }).then(() => {
                this.DesireHelpList();
              });
            } else {
              Dialog.alert({
                title: "提示",
                message: "接单失败!" + res.data.msg,
              }).then(() => {
                // on close
              });
            }
          });
        })
        .catch(() => {
          return false;
        });
    },
  },
};
</script>
<style>
.bianmin {
  -webkit-column-width: 202px;
  -moz-column-width: 202px;
  column-width: 202px;
  padding-bottom: 30px;
  background: #f8f8f8;
  height: 100%;
}
.bianmin .bm-lists {
  margin: 10px;
  background: #fff;
}
.bianmin .bm-listsA {
  padding: 8px 15px;
  border-bottom: 1px solid #f7f8fa;
}
.bianmin .bm-listsB div {
  padding: 2px 15px;
  font-size: 14px;
  color: #333;
}
.bianmin .button {
  display: inline-block;
  margin: 8px 15px;
}
.bianmin .button .van-button {
  height: 30px;
}
.bianmin .button span {
  color: #fff;
}
</style>